<template>
  <div class="manage-project-booking-refunds">
    <div class="container fluid">
      <h4 class="m-2">Manage Project Refunds</h4>
      <refund-table
        :getRefundsAPI="refundAPI.getRefunds"
        :approveRefundAPI="refundAPI.approveRefund"
        :rejectRefundAPI="refundAPI.rejectRefund"
        detailRouteName="ProjectRefundDetails"
      ></refund-table>
    </div>
  </div>
</template>

<script>
import { projectRefund as refundAPI } from "@/api";

export default {
  components: {
    RefundTable: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/RefundTable"
      )
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      refundAPI
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
</style>
